import React from "react";
import { Container, Divider, Header, Table } from "semantic-ui-react";
import { HeaderLined } from "../generic/header-lined";

export function Fiscalite() {
  return (
    <div>
      <Container className="py-10">
          <HeaderLined 
            title="Fiscalité"
          />

        <Header as="h3" color="blue">
          La fiscalité des placements en crowdlending​
        </Header>
        <p className="text-lg">
          Les gains qui proviennent des placements en crowdlending sont soumis
          depuis le 1er janvier 2018 au Prélèvement Forfaitaire Unique (PFU)
          dont le taux est de 30%.​
        </p>
        <p className="text-lg">Le PFU se décompose en :​</p>
        <ul className="text-lg list-disc list-inside">
          <li>Prélèvements sociaux : 17,2%;​</li>
          <li>Acompte sur l’Impôt sur le Revenu : 12,8%.​</li>
        </ul>

        

        <Header as="h3" color="blue">
          Exemple d’un prêt avec échéance constante
        </Header>
        <ul className="text-lg list-disc list-inside">
          <li> Montant du prêt : 2 000€ ;​</li>
          <li>Taux d’intérêt : 1% ;​</li>
          <li>Maturité : 2 ans ;</li>
          <li>Périodicité : mensuelle. Le prélèvement est effectué à chaque mensualité sur les intérêts perçus à chaque échéance ;</li>
        </ul>
        
        <p className="text-lg">
          
          Pour la première année, vous serez donc prélevé de 1,98 euros au titre de l’acompte sur l’impôt sur le revenu 
          et 2,66 euros au titre des prélèvements sociaux (CSG, CRDS) au total. 
          Vous aurez donc reçu sur votre portefeuille Villyz 10,8 euros d’intérêts au titre de la première année.
          <br></br>Pour plus d'information <a href="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/ExempleFiscalite.xlsx" target="_blank">consulter le tableau d'échéance de l'exemple</a> 
        </p>


        <Header as="h3" color="blue">
          Déclaration de vos revenus​
        </Header>
        <p className="text-lg">
          Pour vous aider à déclarer vos revenus provenant des prêts effectués via la plateforme, 
          Villyz met à disposition un Imprimé Fiscal Unique avant le 15 avril suivant la fin de 
          l’année fiscale correspondante.​ Par ailleurs, vous avez la possibilité d’imputer une 
          éventuelle perte en capital subie en cas de non-remboursement d’un prêt des intérêts 
          perçus dans le calcul de l’impôt sur le revenu.​
        </p>

        <Header as="h3" color="blue">
          Puis-je être dispensé du PFU ?​
        </Header>
        <p className="text-lg">
          Les contribuables dont le revenu fiscal de référence de l’année
          précédente est inférieur à 25 000€ pour une personne seule ou
          inférieur à 50 000€ pour un couple peuvent être dispensés de régler
          les 12,8% d’acompte sur l’Impôt sur le Revenu.​
        </p>
      </Container>
    </div>
  );
}
