import { css } from "@emotion/core";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
} from "semantic-ui-react";
import { HeaderLined } from "../generic/header-lined";
import { header, section, sectionHeader, sectionPrimary } from "./styles";

const imageContainer = css({
  display: "flex",
  alignItems: "center",
});

export function Partenaires() {
  return (
    <div>
      <div css={[section, sectionPrimary, header]}>
        <Container>
          <h1 className="text-center py-8 text-4xl">L’écosystème de partenaires​​</h1>
          <p className="text-center text-2xl w-3/4 mx-auto">
            Villyz, c’est aussi un écosystème de partenaires et soutiens qui partagent nos valeurs 
            d’engagement, d’inclusion et d’innovation.
            Grâce à eux, nous améliorons sans cesse notre plateforme et le service rendu aux 
            collectivités et aux citoyens
          </p>
        </Container>
      </div>

      <div css={{ paddingTop: "80px", fontSize: "1.3em" }}>
        <Container>
          <HeaderLined title= "Partenaires bancaires​" />
          <Grid stackable={true}>
            <Grid.Row columns={1}>
              <Grid.Column className="text-center">
                <a 
                  href="https://onlyonecard.eu/"
                  target="_blank">
                <div css={[imageContainer, { height: "120px" }]}>
                  <Image size="medium" centered src="/img/logos/onlyone.png" />
                </div>
                <p className="w-1/3 mx-auto" css={{color: "black"}}>
                  Compte bancaire à impact positif : analyse d’empreinte carbone, 
                  produits d’épargne à impact positif​
                </p>
                </a>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>

      <div css={{ paddingTop: "40px", fontSize: "1.3em" }}>
        <Container>
          <HeaderLined title= "Solution collectivités"/>
          <Grid stackable={true}>
            <Grid.Row columns={3}>
              <Grid.Column className="text-center">
                <a 
                  href = "https://alma-heritage.com/" 
                  target="_blank"
                >
                <div css={[imageContainer, { height: "120px" }]}>
                  <Image
                    size="small"
                    centered
                    src="/img/logos/Logo-Alma.png"
                  />
                </div>
                <p css={{color : "black"}}>
                  Valorise le patrimoine à travers une agence de conseils et une 
                  plateforme de réservation de séjours
                </p>
                </a>
              </Grid.Column>
              <Grid.Column className="text-center">
                <a 
                  href="https://www.bik-air.com/"
                  target="_blank"
                >
                  <div css={[imageContainer, { height: "120px", paddingBottom: "15px"}]}>
                    <Image
                      size="small"
                      centered
                      src="/img/logos/Partenaire_Bikair.png"
                    />
                  </div>
                  <p css={{color : "black"}}>
                    Service de vélos électriques en libre service et sans 
                    borne pour les villes médianes​
                  </p>
                </a>
              </Grid.Column>
              <Grid.Column className="text-center">
                <a 
                  href = "https://www.geosmartic.com/"
                  target="_blank"
                >
                <div css={[imageContainer, { height: "120px" }]}>
                  <Image
                    size="medium"
                    centered
                    src="/img/logos/Partenaire_Geosmartic_logo_Quadri.png"
                  />
                </div>
                <p css={{color : "black"}}>
                  L’information géographique HD et 3D pour les professionnels et collectivités​
                </p>
                </a>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>

      <div css={{ paddingTop: "40px", fontSize: "1.3em" }}>
        <Container>
          
          <HeaderLined title= "Soutiens institutionnels​​" />
          <Grid stackable={true}>
            <Grid.Row columns={3}>
              <Grid.Column className="text-center">
                <a 
                  href="https://www.banquedesterritoires.fr/villyz"
                  target="_blank"  
                >
                  <div css={[imageContainer, { height: "120px" }]}>
                    <Image
                      size="medium"
                      centered
                      src="/img/logos/logo-bdt-mobile.png"
                    />
                  </div>
                  <p css={{color : "black"}}>
                    Accompagne et finance les projets des collectivités. 
                    Référence Villyz comme acteur de la « Smart City »​​
                  </p>
                </a>
              </Grid.Column>
              <Grid.Column className="text-center">
                <a 
                  href= "https://www.bpifrance.fr/ "
                  target= "_blank"  
                >
                  <div css={[imageContainer, { height: "120px" }]}>
                    <Image
                      size="medium"
                      centered
                      src="img/logos/Soutiens_bpifrance.png"
                    />
                  </div>
                  <p css={{color : "black"}}>
                    Soutient l’innovation, la recherche et le développement de 
                    Villyz grâce à INNOV’up ​
                  </p>
                </a>
              </Grid.Column>
              <Grid.Column className="text-center">
                <a 
                  href = "https://www.climate-kic.org/"
                  target="_blank"
                >
                  <div css={[imageContainer, { height: "120px" }]}>
                    <Image
                      size="small"
                      centered
                      src="img/logos/climate-kic-logo.png"
                    />
                  </div>
                  <p css={{color : "black"}}>
                    Organisme européen soutenant la transition vers une 
                    économie plus responsable​​
                  </p>
                </a>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3}>
              <Grid.Column className="text-center">
                <a 
                  href = "https://www.youtube.com/watch?v=XWm2QGf-wVw"
                  target="_blank"
                >
                  <div css={[imageContainer, { height: "160px" }]}>
                    <Image
                      size="medium"
                      centered
                      src="img/logos/new_logo_fft_quadri_cote_bg_white.png"
                    />
                  </div>
                  <p css={{color : "black"}}>
                    Valorise les innovations au service d’une finance durable et 
                    inclusive et développe un écosystème d’acteurs impliqués​
                  </p>
                </a>
              </Grid.Column>
              <Grid.Column className="text-center">
                <a 
                  href="https://francefintech.org/"
                  target="_blank"
                >
                  <div css={[imageContainer, { height: "160px" }]}>
                    <Image
                      size="medium"
                      centered
                      src="/img/logos/Footer_FranceFIntech.png"
                    />
                  </div>
                  <p css={{color : "black"}}>
                    Encourage le développement des Fintechs, Assurtechs et 
                    Regtechs françaises​​
                  </p>
                </a>
              </Grid.Column>
              <Grid.Column className="text-center">
                <a 
                  href= "https://financeparticipative.org/le-college-des-territoires-et-des-institutions-publiques/"
                  target="_blank"
                >
                  <div css={[imageContainer, { height: "160px" }]}>
                    <Image
                      size="medium"
                      centered
                      src="/img/logos/Financement-Participatif-France-2.png"
                    />
                  </div>
                  <p css={{color : "black"}}>
                    Représente les acteurs du financement participatif et promeut les 
                    innovations du secteur.​
                  </p>
                </a>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column className="text-center">
                <a 
                  href= "https://www.laplace-fintech.com/index.html"
                  target= "_blank"
                >
                  <div css={[imageContainer, { height: "150px" }]}>
                    <Image
                      size="small"
                      centered
                      src="img/logos/1580885552528-rsz-laplace-logo-noir-hd.png"
                    />
                  </div>
                  <p css={{color : "black"}}>
                    Lieu où se rencontrent les acteurs de l’innovation en banque, 
                    finance et assurance ​
                  </p>
                </a>
              </Grid.Column>
              <Grid.Column className="text-center">
                <a 
                  href = "https://www.democratieouverte.org/innovateur/villyz​"
                  target= "_blank"
                >
                  <div css={[imageContainer, { height: "150px" }]}>
                    <Image size="medium" centered src="img/logos/Soutiens_Démocratie-ouverte.png" />
                  </div>
                  <p css={{color : "black"}}>
                    Promeut les initiatives démocratiques. Référence Villyz comme innovateur démocratique​
                  </p>
                </a>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
