import React, { useMemo } from "react"
import { Card, CardContent, Icon } from "semantic-ui-react";
import { LightEditableBlock } from "../../../generic/editable-block/light-editable-block";
import Projet from "../../../../model/projet";
import { StatutProjet } from "../../../../model/entities/statut-projet";
import { addDays, differenceInDays, format, parseISO } from "date-fns";
import FinancementDons from "../../forms/financement-dons";
import { AuthenticationState } from "../../../../model/state/authentication-state";
import { ApplicationState } from "../../../../store";
import { useSelector } from "react-redux";
import { ProfilCode } from "../../../../model/dto/response";
import PeriodeDonForm from "../../forms/periode-don-form";
import { ProjetStatut } from "../../projet-statut";
import { InformationsDon } from "./informations-don";

type Props = {
    project: Projet,
    categorieEtablissementCode?: string,
    categorieEtablissementLibelle?: string,
    nonEditListCode: string[];
    nombreDonateur: number;
    onPublishSuccess: (response: Response) => void;
    reload: () => void;
}

export function SummaryBlockDon({project, categorieEtablissementCode, categorieEtablissementLibelle, nonEditListCode, nombreDonateur, reload, onPublishSuccess}: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    
    const daysUntilEnd = useMemo(() => {
        if (
          !project?.DateDebutCollecteDon ||
          !project?.DureeCollecteDon
        ) {
          return "-";
        }
    
        const startDate = parseISO(project.DateDebutCollecteDon!);
        
        const endDate = addDays(startDate, project.DureeCollecteDon!+1);
        return new Intl.NumberFormat("fr-FR", {
          style: "decimal",
        }).format(differenceInDays(new Date(), endDate));
      }, [project]);


    const endDate = useMemo(() => {
        if(project.DateClotureDon) {
          return parseISO(project.DateClotureDon)
        }
    
        if (
          !project?.DateDebutCollecteDon ||
          !project?.DureeCollecteDon
        ) {
          return undefined;
        }
        const startDate = parseISO( project.DateDebutCollecteDon); 
        return addDays(startDate, project.DureeCollecteDon);
      }, [project]);

      
    return (
    <Card fluid>
      <CardContent css={{height:"15%"}}>
            <LightEditableBlock
                title="Période de collecte"
                editable={(loginProps.oauth?.profilCode === ProfilCode.PORTEUR_PROJET && !(nonEditListCode.includes(project.StatutDonProjetCode!)) || loginProps.oauth?.profilCode === ProfilCode.VILLYZ)}
                    editor={(close) => (
                    <PeriodeDonForm
                        projet={project}
                        onSubmitSuccess={() => {
                            close();
                            reload();
                        }}
                    />
                )}
                iconCss={{
                  position: "absolute",
                  right:"10px",
                  top:"10px"
                }}
                > 
                {(project.StatutDonProjetCode === StatutProjet.COMPLET || 
                project.StatutDonProjetCode === StatutProjet.INCOMPLET || 
                project.StatutDonProjetCode === StatutProjet.EN_TRAITEMENT || 
                project.StatutDonProjetCode === StatutProjet.INFORMATION_MANQUANTE || 
                project.StatutDonProjetCode === StatutProjet.A_VENIR) && (
                    <div css={{height:"100%", display: "flex", flexDirection:"column", justifyContent:"center", alignItems: "center" }}>
                        <span css={{ fontSize: "1.2em", fontWeight: "bold" }}>
                            <Icon name="clock" size="large" /> J{daysUntilEnd}
                        </span>
                        {endDate && (
                            <span css={{ fontSize: ".9em", display: "flex", justifyContent:"center", alignItems: "center"}}>
                            Termine le {format(endDate, "dd/MM/yy")}
                            </span>
                        )}
                    </div>
                )}
                <span css={{display:"flex !important", alignItems:"center !important", justifyContent:"center !important", marginTop:"1rem !important"}}>
                    <ProjetStatut 
                        daysUntilEnd={daysUntilEnd} 
                        statut={project.StatutDonProjetCode!} 
                        categorieEtablissementCode={categorieEtablissementCode}
                        categorieEtablissementLibelle={categorieEtablissementLibelle}
                    />
                </span>
            </LightEditableBlock>
        </CardContent>
        <CardContent css={{position: "relative", height:"75%", padding:"0px 10px !important"}}>  
          <LightEditableBlock
            title="Caractéristiques du don"
            editable={(loginProps.oauth?.profilCode === ProfilCode.PORTEUR_PROJET && !(nonEditListCode.includes(project.StatutDonProjetCode!))) || loginProps.oauth?.profilCode === ProfilCode.VILLYZ}
            editor={(close) => (
                <FinancementDons
                    projet={project}
                    onSubmitSuccess={() => {
                        close();
                        reload();
                    }}
                />
            )}
            iconCss={{
              position: "absolute",
              right:"12px",
              top:"10px"
            }}
          >
            <InformationsDon
              project= {project} 
              nombreDonateur={nombreDonateur} 
              endDate={endDate!}
              reload={reload}
              onPublishSuccess={onPublishSuccess} 
            />
          </LightEditableBlock>
      </CardContent>
    </Card>
    )
}