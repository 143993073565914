import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Risques } from "../components/pages/risques";
import { Stats } from "../components/pages/stats";
import { Fiscalite } from "../components/pages/fiscalite";
import { Partenaires } from "../components/pages/partenaires";
import { Protection } from "../components/pages/protection";
import { FaqPage } from "../components/pages/faq-page";
import { MentionsLegales } from "../components/pages/mentions-legales";
import { PrivateRouteStorage } from "../routing/private-route";
import { ScrollToTop } from "./scroll-to-top";
import { ApplicationHeader } from "../components/pages/application-header";
import { Footer } from "../components/pages/footer";
import { ProjetDetailRouter } from "./projet-detail-router";
import { Provider } from "react-redux";
import { store } from "../routing/redux-store";
import { LoginContainerFunction } from "../components/login/login-container";
import { TestMangoPay } from "../components/testMangoPay/test-mangopay-page";
import { RemerciementInvestissementRouter } from "./remerciement-investissement-router";
import { RemerciementDebouclageRouter } from "./remerciement-debouclage-router";
import { TestPdfGenerator } from "../components/testPdfGenerator/pdf-generator-page-test";
import { DashboardAdminDetail } from "../components/dashboard-admin/dashboard-admin-detail";
import { DashboardCollectivite } from "../components/profil/dashboard/collectivite";
import { DashboardCitoyen } from "../components/profil/dashboard/citoyen";
import { ResetPassword } from "../components/login/reset-password";
import { ForgotPassword } from "../components/login/forgot-password";
import { ServerError } from "../components/errors/server-error";
import { Forbidden } from "../components/errors/forbidden";
import { Disconnected } from "../components/errors/disconnected";
import { Empty } from "../components/errors/empty";
import { Error404 } from "../components/errors/not-found";
import { ValidateEmail } from "../components/login/validate-email";
import { ProjetInvestRouterV2 } from "./projet-invest-router-v2";
import { CitoyenDetailRouter } from "./citoyen-detail-router";
import { CollectiviteDetailRouter } from "./collectivite-detail-router";
import { Reclamations } from "../components/ecsp/reclamations";
import { SwitchProfilContainer } from "../components/profil/switch-profil-container";
import { HomeV3 } from "../components/pages/home/home-v3";
import { ProjetListNew } from "../components/projet/projet-list-new";
import { CollectivitesV2 } from "../components/pages/collectivités/collectivites-v2";
import { EngagementsV2 } from "../components/pages/engagements/engagementsV2";
import { SignUp } from "../components/login/SignUp/SignUp";
import { LoginV2 } from "../components/login/LoginV2/LoginV2";
import { ProjetDonDetailRouter } from "./projet-don-detail-router";
import { ProjetDonInvestRouter } from "./projet-don-invest-router";

export class AppMainRouter extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <ScrollToTop />
                    <ApplicationHeader />
                    <Routes>
                        <Route path="/" element={<HomeV3 />} />
                        <Route
                            path="/collectivites"
                            element={<CollectivitesV2 />}
                        />
                        <Route path="/statistiques" element={<Stats />} />
                        <Route path="/risques" element={<Risques />} />
                        <Route path="/fiscalite" element={<Fiscalite />} />
                        <Route path="/partenaires" element={<Partenaires />} />
                        <Route path="/protection" element={<Protection />} />
                        <Route
                            path="/engagements"
                            element={<EngagementsV2 />}
                        />
                        <Route path="/faq-page" element={<FaqPage />} />
                        <Route
                            path="/mentions-legales"
                            element={<MentionsLegales />}
                        />

                        <Route path="/erreur" element={<ServerError />} />

                        <Route path="/interdit" element={<Forbidden />} />

                        <Route path="/deconnexion" element={<Disconnected />} />

                        <Route path="/vide" element={<Empty />} />

                        <Route
                            path="/valider-email/:encodedValidationCode"
                            element={<ValidateEmail />}
                        />

                        <Route
                            path="/projets/:projetId/investissement/v2"
                            element={<ProjetInvestRouterV2 />}
                        />

                        <Route
                            path="/projets/:projetId/investissement/don"
                            element={<ProjetDonInvestRouter />}
                        />

                        <Route
                            path="/projets/:slug/"
                            element={<ProjetDetailRouter />}
                        />

                        <Route
                            path="/projets/:slug/don"
                            element={<ProjetDonDetailRouter />}
                        />

                        <Route path="/projets" element={<ProjetListNew />} />

                        <Route
                            path="/reclamations"
                            element={<Reclamations />}
                        />

                        {/* Private routes */}
                        <Route
                            path="/citoyens/:userId/"
                            element={
                                <PrivateRouteStorage
                                    component={CitoyenDetailRouter}
                                />
                            }
                        />

                        <Route
                            path="/collectivites/:userId/"
                            element={
                                <PrivateRouteStorage
                                    component={CollectiviteDetailRouter}
                                />
                            }
                        />

                        <Route
                            path="/profil"
                            element={
                                <PrivateRouteStorage
                                    component={SwitchProfilContainer}
                                />
                            }
                        />

                        <Route
                            path="/projets/debouclage/remerciement"
                            element={
                                <PrivateRouteStorage
                                    component={RemerciementDebouclageRouter}
                                />
                            }
                        />

                        <Route
                            path="/projets/investissement/remerciement"
                            element={
                                <PrivateRouteStorage
                                    component={RemerciementInvestissementRouter}
                                />
                            }
                        />

                        <Route
                            path="/testMangoPay"
                            element={
                                <PrivateRouteStorage component={TestMangoPay} />
                            }
                        />

                        <Route
                            path="/dashboard/admin"
                            element={
                                <PrivateRouteStorage
                                    component={DashboardAdminDetail}
                                />
                            }
                        />

                        <Route
                            path="/dashboard/collectivite"
                            element={
                                <PrivateRouteStorage
                                    component={DashboardCollectivite}
                                />
                            }
                        />

                        <Route
                            path="/dashboard/citoyen"
                            element={
                                <PrivateRouteStorage
                                    component={DashboardCitoyen}
                                />
                            }
                        />

                        <Route
                            path="/testPdfGenerator"
                            element={
                                <PrivateRouteStorage
                                    component={TestPdfGenerator}
                                />
                            }
                        />

                        <Route
                            path="/login"
                            element={<LoginContainerFunction />}
                        />
                        <Route
                            path="/inscription"
                            element={<SignUp />}
                        />
                        <Route
                            path="/connexion"
                            element={<LoginV2 />}
                        />
                        <Route
                            path="/forgotPassword"
                            element={<ForgotPassword />}
                        />
                        <Route
                            path="/resetPassword/:token"
                            element={<ResetPassword />}
                        />

                        <Route element={<Error404 />} />
                    </Routes>
                    <Footer />
                </Router>
            </Provider>
        );
    }
}
