import React from "react"
import { Accordion, Container, Header } from 'semantic-ui-react'
import { section } from "./styles"
import { citoyenPanelGeneral, citoyenPanelPretParticipatifCitoyen, citoyenPanelRisques, projetsPanel,mecenatEntreprisePanel} from "./faq-questions";

export function FaqPage(){
    return (
        <div css={[section]}>
            <Container>
            <Header as="h1" color="blue" css={{textAlign : "center", marginTop : "-50px"}}>
                FOIRE AUX QUESTIONS​
            </Header>
            {/* <Tab menu={{ secondary: true, pointing: true }} panes={panes} /> */}
            <Header as="h2" color="blue" css={{textAlign : "center"}}>
                Général
            </Header>
            <Accordion defaultActiveIndex={0} fluid styled panels={citoyenPanelGeneral} />
            <Header as="h2" color="blue" css={{textAlign : "center"}}>
                Investissement citoyen
            </Header>
            <Accordion defaultActiveIndex={0} fluid styled panels={citoyenPanelPretParticipatifCitoyen} />
            <Header as="h2" color="blue" css={{textAlign : "center"}}>
                Risques
            </Header>
            
            <Accordion defaultActiveIndex={0} fluid styled panels={citoyenPanelRisques} />
            <Header as="h2" color="blue" css={{textAlign : "center"}}>
                Projets 
            </Header>
            <Accordion defaultActiveIndex={0} fluid styled panels={projetsPanel} />
            <Header as="h2" color="blue" css={{textAlign : "center"}}>
                Mécénat d'entreprise
            </Header>
            <Accordion defaultActiveIndex={0} fluid styled panels={mecenatEntreprisePanel} />
            </Container>
        </div>
    )
}